import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { COUNTRY_CODES } from '@core/models/hdbk/country.model';
import { CityService } from '@core/services/hdbk/city.service';
import { LanguageService } from '@core/services/language.service';
import { CartViewService } from 'src/app/cart/services/cart-view.service';


@Injectable()
export class CartMessagesService {

  productsToCheck = [
    'cardio-mix',
    'respira-mix',
    'arthro-mix',
    'endo-mix',
    'nervo-mix',
    'immuno-mix',
    'visio-mix',
    'gastro-mix',
    'hepa-mix',
    'antihelm-mix',
    'reno-mix',
    'mens-formula',
    'beauty-formula',
    'womens-formula',
    'weight-control-mix'];

  countriesToCheck = [
    COUNTRY_CODES.Russia, COUNTRY_CODES.Belarus, COUNTRY_CODES.Kazakhstan, COUNTRY_CODES.Kyrgyzstan, COUNTRY_CODES.Azerbaijan
  ];

  constructor(private cityService: CityService, private dialog: MatDialog, private langugeService: LanguageService,
    private cartViewService: CartViewService) { }

  showProductMessage(slug: string) {
    // const country = this.cityService.getCountry();
    // if (this.productsToCheck.indexOf(slug) !== -1 && this.countriesToCheck.indexOf(country.alpha2 as COUNTRY_CODES) !== -1) {
    //   const message = PRODUCT_WARNING_MSG[this.langugeService.getLanguage()] ?
    //     PRODUCT_WARNING_MSG[this.langugeService.getLanguage()] : PRODUCT_WARNING_MSG.en;
    //   this.dialog.open(TextDialogComponent, {
    //     width: '300px',
    //     panelClass: 'modal-form-error',
    //     data: message
    //  });
    // }
  }

}
