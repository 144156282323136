
export const FAV_DELETE_MESSAGE = {
  ru: 'Вы действительно хотите удалить товар из избранного?',
  en: 'Are you sure you want to remove the product from your favorites?',
  bg: 'Сигурни ли сте, че искате да премахнете елемента от любимите си?',
  cs: 'Opravdu chcete odebrat položku ze svých oblíbených položek?',
  de: 'Möchten Sie den Artikel wirklich aus Ihren Favoriten entfernen?',
  es: 'Estás seguro de que deseas eliminar el elemento de tus favoritos?',
  tr: 'Öğeyi favorilerinizden kaldırmak istediğinizden emin misiniz?',
  hu: 'Biztosan eltávolít egy elemet a kedvencek közül?',
};

export const LINK_COPIED_MESSAGE = {
  ru: 'Ссылка скопирована',
  en: 'The link was copied',
  bg: 'Връзката е копирана',
  cs: 'Odkaz byl zkopírován',
  de: 'Link kopiert',
  es: 'Enlace copiado',
  tr: 'Bağlantı kopyalandı',
  hu: 'Link másolva',
};

export const ADDRESS = {
  house: {
    ru: 'дом',
    en: 'house',
    bg: 'къща',
    cs: 'dům',
    de: 'haus',
    es: 'casa',
    tr: 'ev',
    hu: 'ház'
  },
  build_block: {
    ru: 'корп./стр',
    en: 'building/structure',
    bg: 'сграда/структура',
    cs: 'budova/struktura',
    de: 'körper/struktur',
    es: 'estructura/construcción',
    tr: 'bina/yapısı',
    hu: 'épület/szerkezete',
  },
  flat: {
    ru: 'кв./офис',
    en: 'apartment/office',
    bg: 'апартаменти/офис',
    cs: 'bytová/kancelář',
    de: 'eben/büro',
    es: 'plano/oficina',
    tr: 'düz/ofis',
    hu: 'lakás/iroda',
  },
  zip_code: {
    ru: 'индекс',
    en: 'zip code',
    bg: 'пощенски код',
    cs: 'PSČ',
    de: 'PLZ',
    es: 'código postal',
    tr: 'posta kodu',
    hu: 'index',
  }
};

export const META_MESSAGES = {
  main_page_title: {
    ru: 'Vertera - продукция для красоты и здоровья из ламинарии',
    en: 'Vertera - health and beauty products from kelp',
    bg: 'Vertera - продукти за здраве и красота от водорасли',
    cs: 'Vertera - výrobky pro zdraví a krásu z řasy',
    de: 'Vertera - Gesundheits- und Schönheitsprodukte von Seetang',
    es: 'Vertera - productos de salud y belleza de kelp',
    tr: 'Vertera - kelp\'ten sağlık ve güzellik ürünleri',
    hu: 'Vertera - termékek a szépségért és az egészségért hínárból',
  },
  main_page_description: {
    ru: 'Vertera – это водорослевое питание с органическим йодом и косметика со 100% натуральным составом. Скидка 30% после регистрации!',
    en: 'Vertera is an algal food with organic iodine and wrap cosmetics with a 100% natural composition. 30% discount after registration!',
    bg: 'Vertera е водорасла храна с органичен йод и обвиваща козметика със 100% натурален състав. 30% отстъпка след регистрация!',
    cs: 'Vertera je jídlo z řas s organickým jódem a kosmetika se 100% přírodním složením. 30% sleva po registraci!',
    de: 'Vertera ist ein Algenfutter mit Bio-Jod und Wrap-Kosmetik mit einer 100% natürlichen Zusammensetzung. 30% Rabatt nach Anmeldung!',
    es: 'Vertera es un alimento de algas con yodo orgánico y cosméticos envolventes con una composición 100% natural. ¡30% de descuento después del registro!',
    tr: 'Vertera, organik iyotlu bir alg gıdasıdır ve% 100 doğal bir bileşime sahip sargı kozmetikler. Kayıttan sonra% 30 indirim!',
    hu: 'A Vertera tengeri moszat táplálék, amely szerves jódot és kozmetikumokat tartalmaz, 100% -ban természetes összetételű. Regisztráció után 30% kedvezmény!',
  },
  delivery_page_title: {
    ru: 'Доставка продукции Vertera',
    en: 'Vertera Product Delivery',
    bg: 'Доставка на продукт Vertera',
    cs: 'Dodávka produktu Vertera',
    de: 'Vertera Produktlieferung',
    es: 'Entrega de productos Vertera',
    tr: 'Vertera Ürün Teslimi',
    hu: 'Vertera termékek szállítása',
  },
  delivery_page_description: {
    ru: 'Узнайте подробнее о способах доставки любимых продуктов из магазина Vertera',
    en: 'Learn more about shipping options for your favorite products from the Vertera store',
    bg: 'Научете повече за опциите за доставка на любимите си продукти от магазина на Vertera',
    cs: 'Další informace o možnostech dopravy vašich oblíbených produktů z obchodu Vertera',
    de: 'Weitere Informationen zu den Versandoptionen für Ihre Lieblingsprodukte erhalten Sie im Vertera Store',
    es: 'Obtenga más información sobre las opciones de envío de sus productos favoritos en la tienda Vertera',
    tr: 'Vertera mağazasından sevdiğiniz ürünlerin gönderim seçenekleri hakkında daha fazla bilgi edinin',
    hu: 'További információ a kedvenc Vertera élelmiszerek kiszállításáról',
  },
  category_page_title: (category: string) => {
    return {
      ru: `${category} в магазине Vertera`,
      en: `${category} at Vertera Store`,
      bg: `${category} в магазин Vertera`,
      cs: `${category} v obchodě Vertera`,
      de: `${category} im Vertera Store`,
      es: `${category} en la Tienda Vertera`,
      tr: `Vertera Store'da ${category}`,
      hu: `${category} a Vertera üzletben`,
    }
  },
  category_page_description: {
    ru: 'Официальный сайт компании Vertera: каталог продукции. Водорослевое питание и косметика, домашнее SPA, продукты для иммунитета.',
    en: 'Vertera official website: product catalog. Algae nutrition and cosmetics, home SPA, immunity products.',
    bg: 'Официален сайт на Vertera: каталог на продукти. Хранене и козметика за водорасли, домашен SPA, продукти за имунитет.',
    cs: 'Oficiální stránky Vertera: katalog produktů. Výživa a kosmetika řas, domácí SPA, produkty imunity.',
    de: 'Offizielle Website von Vertera: Produktkatalog. Algenernährung und Kosmetik, Home SPA, Immunitätsprodukte.',
    es: 'Sitio web oficial de Vertera: catálogo de productos. Nutrición y cosmética de algas, SPA casero, productos de inmunidad.',
    tr: 'Vertera resmi web sitesi: ürün kataloğu. Yosun beslenme ve kozmetik, ev SPA, bağışıklık ürünleri.',
    hu: 'A Vertera hivatalos weboldala: termékkatalógus. Alga táplálkozás és kozmetikumok, otthoni SPA, immunitást elősegítő termékek.',
  },
  product_page_description: (product: string) => {
   return {
      ru: `${product}: описание продукта, инструкция по применению, цена и отзывы на официальном сайте Vertera`,
      en: `${product}: product description, instructions for use, price and reviews on the official website of Vertera`,
      bg: `${product}: описание на продукта, инструкции за употреба, цена и отзиви на официалния уебсайт на Vertera`,
      cs: `${product}: popis produktu, návod k použití, cena a recenze na oficiálních stránkách Vertera`,
      de: `${product}: Produktbeschreibung, Gebrauchsanweisung, Preis und Bewertungen auf der offiziellen Website von Vertera`,
      es: `${product}: descripción del producto, instrucciones de uso, precio y comentarios en el sitio web oficial de Vertera`,
      tr: `${product}: Vertera resmi web sitesinde ürün açıklaması, kullanım talimatları, fiyat ve yorumlar`,
      hu: `${product}: termékleírás, használati utasítás, ár és vélemények a Vertera hivatalos weboldalán`,
    }
  },
  production_page_description: {
    ru: 'Vertera – это водорослевое питание с органическим йодом и косметика со 100% натуральным составом. Скидка 30% после регистрации!',
    en: 'Vertera is a seaweed nutrition with organic iodine and cosmetics with 100% natural composition. 30% discount after registration!',
    bg: 'Vertera е хранене с водорасли с органичен йод и козметика със 100% естествен състав. 30% отстъпка след регистрация!',
    cs: 'Vertera je výživa mořských řas s organickým jódem a kosmetikou se 100% přírodním složením. 30% sleva po registraci!',
    de: 'Vertera ist eine Algenernährung mit Bio-Jod und Kosmetika mit 100% natürlicher Zusammensetzung. 30% Rabatt nach Anmeldung!',
    es: 'Vertera es una nutrición de algas con yodo orgánico y cosmética con composición 100% natural. ¡30% de descuento después del registro!',
    tr: 'Vertera, organik iyot ve% 100 doğal bileşime sahip kozmetikler içeren bir deniz yosunu besinidir. Kayıttan sonra% 30 indirim!',
    az: 'Vertera,% 100 təbii tərkibli üzvi yod və kosmetik məhsullarla yosun qidasıdır. Qeydiyyatdan sonra 30% endirim!',
    hu: 'A Vertera tengeri moszat táplálék, amely szerves jódot és kozmetikumokat tartalmaz, 100% -ban természetes összetételű. Regisztráció után 30% kedvezmény!',
  }
};

export const FILTER_OPTIONS = {
  all: {
    ru: 'Все',
    en: 'All',
    bg: 'Всичко',
    cs: 'Všechno',
    de: 'Alles',
    es: 'Todas',
    tr: 'Herşey',
    az: 'Hamısı',
    hu: 'Minden',
  },
  yes: {
    ru: 'Да',
    en: 'Yes',
    bg: 'Да',
    cs: 'Ano',
    de: 'Ja',
    es: 'Si',
    tr: 'Evet',
    az: 'Bəli',
    hu: 'Igen',
  },
  no: {
    ru: 'Нет',
    en: 'No',
    bg: 'Не',
    cs: 'Ne',
    de: 'Nein',
    es: 'No',
    tr: 'Değil',
    az: 'Yox',
    hu: 'Nem',
  }
}

export const BPV_AND_HIGHER_MSG = {
  ru: '40 PV и выше',
  en: '40 PV and higher',
  cs: '40 PV a výše',
  bg: '40 PV и повече',
  es: '40 PV y más',
  tr: '40 PV ve üstü',
  az: '40 PV və daha yüksək',
  hu: '40 PV és magasabb',
}

export const BPV_FIRST_RANK = {
  ru: 'PV в первой линии',
  en: 'PV in the first rank',
  cs: 'PV V prvním řádku je',
  bg: 'PV на първа линия',
  es: 'PV en la primera línea',
  tr: 'PV İlk satırd',
  az: 'PV birinci xəttdə',
  hu: 'PV az első sorban',
}

export const DATA_COLLECTION = {
  ru: 'Идет сбор данных',
  en: 'Data collection in progress',
  bg: 'В процес на събиране на данни',
  cs: 'Probíhá sběr dat',
  de: 'Datenerfassung läuft',
  es: 'Recopilación de datos en curso',
  tr: 'Veri toplama devam ediyor',
  az: 'Məlumat toplanması davam edir',
  hu: 'Adatgyűjtés folyamatban',
}

export const CLUB_200_MSG = {
  ru: 'Нельзя одновременно применить фильтры "Клуб 200 в периоде"=Да и "Был в Клуб 200"=Нет. Выберите другие условия',
  en: 'You cannot apply the filters "Club 200 in period"=Yes and "Was in Club 200"=No at the same time. Select other conditions',
  bg: 'Не можете да приложите едновременно филтрите "Клуб 200 в период"=Да и "Бил в Клуб 200"=Не. Изберете други условия',
  cs: 'Nelze použít filtry „Klub 200 v období“=Ano a „Byl v klubu 200“=Ne současně. Vyberte další podmínky',
  de: 'Sie können die Filter "Club 200 in Periode"=Ja und "War in Club 200"=Nein nicht gleichzeitig anwenden.' +
  ' Wählen Sie andere Bedingungen',
  es: 'No puede aplicar los filtros "Club 200 en período"=Sí y "Estaba en Club 200"=No al mismo tiempo. Seleccione otras condiciones',
  tr: '"Club 200 dönemindeki=Evet ve "Club 200 teydi"=Hayır filtrelerini aynı anda uygulayamazsınız. Diğer koşulları seçin',
  az: '"Dövrdə Club 200"= Bəli və "Klub 200də Oldum"=Xeyr eyni zamanda filtrləri tətbiq edə bilməzsiniz. Digər şərtləri seçin',
  hu: 'Nem alkalmazhatja a "Club 200 in period" = Igen és a "Volt a Club 200 -ban" = Nem szűrőt egyszerre. Válasszon más feltételeket',
}

export const GIFTS_AVAILABLE_MSG = {
  ru: 'Доступно еще {n} подарка с учетом набранной корзины',
  en: 'You can get {n} more gift according to the items in cart',
  bg: 'Предлагат се още {n} подаръка, като се вземе предвид събраната кошница',
  cs: 'K dispozici jsou ještě {n} dárky s přihlédnutím k obsahu košíku',
  es: 'Hay {n} regalos más disponibles, teniendo en cuenta lo que se ha reunido ya en la cesta',
  tr: 'Sepetin içeriği dikkate alarak {n} hediye daha alınabilir',
  de: 'Es stehen noch {n} weitere Geschenke zur Verfügung, wobei der gesammelte Warenkorb berücksichtigt wird',
  sr: 'Na raspolaganju su još {n} poklona, uključujući sadržaj korpe',
  mk: 'Достапно е уште {n} подароци, земаjки предвид кои стоки ги имаш во кошницата',
  az: 'Toplanmış səbətlə yanaşı daha {n} hədiyyə mövcuddu',
  hu: '{n} további ajándék áll rendelkezésre, figyelembe véve az összegyűjtött kosarat',
};

export const GIFTS_ACCEPT_MSG = {
  ru: 'Забрать подарки',
  en: 'Get your gifts',
  bg: 'Вземете подаръци',
  cs: 'Pokračujte a vyberte si dárek',
  es: 'Recoger regalos',
  tr: 'Hediyeleri al',
  de: 'die Geschenke abholen',
  sr: 'Preuzmi poklone',
  mk: 'Продолжи без подароците',
  az: 'Hədiyyələri götür',
  hu: 'Vedd fel az ajándékokat',
}

export const GIFTS_DENY_MSG = {
  ru: 'Продолжить без подарков',
  en: 'Continue without gifts',
  bg: 'Продължете без подаръци',
  cs: 'Pokračovat bez dárků',
  es: 'Continuar sin regalos',
  tr: 'Hediye almadan devam et',
  de: 'ohne Geschenke fortsetzen',
  sr: 'Nastavi bez poklona',
  mk: 'Продолжи без подароците',
  az: 'Hədiyyələrsiz davam et',
  hu: 'Folytasd ajándékok nélkül',
}

export const SAVE_SUCCESS_MSG = {
  ru: 'Данные сохранены',
  en: 'Data saved',
  bg: 'Данните са запазени',
  cs: 'Data uložena',
  es: 'Datos guardados',
  tr: 'Veri kaydedildi',
  de: 'Daten gespeichert',
  sr: 'Подаци су сачувани',
  mk: 'Податоците се зачувани',
  az: 'Data qeyd edildi',
  hu: 'Adatok mentve',
}

export const PARTNERS_LIMIT_MSG = {
  ru: 'Более 10 партнеров выбрать нельзя',
  en: 'More than 10 partners cannot be selected',
  bg: 'Не могат да бъдат избрани повече от 10 партньори',
  cs: 'Nelze vybrat více než 10 partnerů',
  es: 'No se pueden seleccionar más de 10 socios',
  tr: '10\'dan fazla ortak seçilemez',
  de: 'Mehr als 10 Partner können nicht ausgewählt werden',
  sr: 'Не може се одабрати више од 10 партнера',
  mk: 'Повеќе од 10 партнери не можат да бидат избрани',
  az: '10-dan çox tərəfdaş seçilə bilməz',
  hu: '10 -nél több partner nem választható ki',
}

export const DATA_ERROR = {
  ru: 'По техническим причинам мы не можем сейчас отобразить данные. Попробуйте зайти через 10 минут',
  en: 'For technical reasons, we are unable to display the data right now. Try again in 10 minutes',
  bg: 'По технически причини не можем да покажем данните в момента. Опитайте отново след 10 минути',
  cs: 'Z technických důvodů momentálně nemůžeme data zobrazit. Zkuste to znovu za 10 minut',
  es: 'Por razones técnicas, no podemos mostrar los datos en este momento. Vuelve a intentarlo en 10 minutos',
  tr: 'Teknik nedenlerden dolayı şu anda verileri görüntüleyemiyoruz. 10 dakika sonra tekrar deneyin',
  de: 'Aus technischen Gründen können wir die Daten derzeit nicht anzeigen. Versuche es in 10 Minuten noch einmal',
  sr: 'Из техничких разлога тренутно нисмо у могућности да прикажемо податке. Покушајте поново за 10 минута',
  mk: 'Од технички причини, не можеме да ги прикажеме податоците во моментов. Обидете се повторно за 10 минути',
  az: 'Texniki səbəblərə görə məlumatları hazırda göstərə bilmirik. 10 dəqiqədən sonra yenidən cəhd edin',
  hu: 'Technikai okokból jelenleg nem tudjuk megjeleníteni az adatokat. Próbáljon 10 perc múlva bejelentkezni',
}

export const FAQ_NOT_FOUND = {
  ru: 'По вашему запросу ничего не найдено',
  bg: 'Няма намерени резултати за Вашето търсене',
  cs: 'Na vaši žádost nebylo nic nalezeno',
  en: 'No results found',
  es: 'No se encontraron resultados para su búsqueda',
  de: 'Es wurden keine Ergebnisse für Ihre Suche gefunden',
  mk: 'На ваше барање ништо не е пронајдено',
  sr: 'Nema rezultata za Мašu pretragu',
  hu: 'Nincs találat a keresésre',
}
