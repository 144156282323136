import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { UserModel } from '@core/models';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {
  user$ = new ReplaySubject<UserModel>(1);
  acceptCookies$ = new ReplaySubject<boolean>(1);

  constructor(private userService: UserService) { }

  getUser() {
    this.userService.getUser().subscribe((user: UserModel) => {
      if (user && user.hasOwnProperty('is_accepted_cookies')) {
        this.acceptCookies$.next(user.is_accepted_cookies);
      }
      if (user && user.id) {
        this.user$.next(user);
      } else {
        this.user$.next(null);
      }
    }, () => this.user$.next(null));
  }
}
