import { Injectable, Inject, Injector } from '@angular/core';
import { OrderQuery, OrderInfo, Order } from '@core/models';
import { CurrencyService } from '@core/services/currency.service';
import { environment } from 'src/environments/environment';
import { AppStorage } from '@core/storage';
import { CartViewService } from './cart-view.service';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { CityService } from '@core/services/hdbk/city.service';


@Injectable({
  providedIn: 'root'
})
export class OrderQueryBuilderService {
  private order: OrderQuery = new OrderQuery();
  readonly orderKey: string = `${environment.appName}_order`;

  constructor(private currencyService: CurrencyService,
              @Inject(AppStorage) private appStorage: Storage,
              private injector: Injector, private authTokenService: AuthTokenService,
              private cityService: CityService) { }

  createOrderQuery(data: OrderQuery | Order, address)  {
    this.order = { ...this.order, ...data };
    this.order.currency_code = this.currencyService.getCurrency();
    this.order.agree = true;
    const city = this.cityService.getCity();
    this.order.delivery_city_id = city.id;
    this.order.delivery_country_id  = city.country.id;
    this.saveOrderInfo(this.order, address);
  }

  getOrderQuery(): OrderQuery {
    return {...this.order};
  }


  saveOrderInfo(order: Order | any, address: string) {
    let totalPrice;
    let totalPriceWithDelivery;
    let discount;
    const cartViewService = this.injector.get(CartViewService);
    const { tax } = cartViewService;
    if (this.authTokenService.getAuthHeader()) {
      totalPrice = cartViewService.totalPrice;
      totalPriceWithDelivery = cartViewService.totalPriceWithDelivery;
      discount = cartViewService.discount;
    } else {
      totalPrice = cartViewService.totalCustomerPrice;
      totalPriceWithDelivery = cartViewService.totalCustomerPriceWithDelivery;
      discount = cartViewService.discount;
    }

    const orderInfo: OrderInfo = {
      ...this.order,
      ...{id: order.id, id_os : order.id_os, address},
      ...{totalPrice, totalPriceWithDelivery, discount, tax}
    };
    this.appStorage.setItem(this.orderKey, JSON.stringify(orderInfo));
  }

  getOrderInfo(): OrderInfo {
    const orderInfo = this.appStorage.getItem(this.orderKey);
    return orderInfo ? JSON.parse(orderInfo) : null;
  }

  removeOrderInfo() {
    this.appStorage.removeItem(this.orderKey);
  }
}
