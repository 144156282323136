<app-cookies></app-cookies>

<footer class="footer">
  <div class="container">
    <div class="footer__col">
      <div class="footer__col--title">Online mağaza</div>
      <div class="footer__menu">
        <a routerLink="/catalog" class="footer__menu--link">Urün kataloğu</a>
        <a class="footer__menu--link" routerLink="/teslim">Teslim </a>
        <a class="footer__menu--link" routerLink="/odeme">ödeme </a>
        <!--
        <a href="#" class="footer__menu--link">Оплата</a>
        -->
      </div>
    </div>

    <div class="footer__col md">
      <div class="footer__col--title">Vertera</div>
      <div class="footer__menu">
        <a routerLink="/about" class="footer__menu--link">Vertera hakkında</a>
        <!--
        <a href="https://old.dev.vertera.org/geography" target="_blank" class="footer__menu--link">География</a>
        <a href="#" class="footer__menu--link">Партнерская программа</a>
        -->
        <a routerLink="/blog" class="footer__menu--link" i18n="@@common.blog">Blog</a>
        <!-- <a *ngIf="!user" href="https://os.verteraorganic.com/lkc.php" class="footer__menu--link" target="_blank" i18n>Partner's office</a> -->
      </div>
      <div class="footer__menu">
        <!-- <a routerLink="/faq" target="_blank" class="footer__menu--link" i18n>Help</a> -->
        <!-- <a routerLink="/callback" class="footer__menu--link" i18n>Call order</a> -->

        <a *ngIf="!user" (click)='login()' class="footer__menu--link" i18n="@@sign_in">
          Sign in
        </a>
        <a *ngIf="!user" (click)='register()' class="footer__menu--link" i18n="@@sing_up">
          Sign up
        </a>
        <!-- <a *ngIf="user" href="https://os.verteraorganic.com/lkc.php" class="footer__menu--link" target="_blank" i18n>Partner's office</a> -->
      </div>
    </div>

    <div class="footer__col lg-max">
      <div class="footer__col--title" >Vertera</div>

      <div class="footer__menu">
        <a routerLink="/about" class="footer__menu--link">Vertera hakkında</a>
        <a routerLink="/blog" class="footer__menu--link" i18n="@@common.blog">Blog</a>
        <!-- <a href="https://old.dev.vertera.org/geography" target="_blank" class="footer__menu--link">География</a>
        <a href="#" class="footer__menu--link">Партнерская программа</a>
        -->
        <!--
        <a *ngIf="!user" href="https://os.verteraorganic.com/lkc.php" class="footer__menu--link" target="_blank" i18n>Partner's office</a>
        -->
      </div>
    </div>

    <div class="footer__col lg-max">
      <div class="footer__menu custom-block">
        <!-- <a routerLink="/faq" target="_blank" class="footer__menu--link" i18n>Help</a>
        <a routerLink="/callback" class="footer__menu--link" i18n>Call order</a> -->
        <a *ngIf="!user" (click)='login()' class="footer__menu--link" i18n="@@sign_in">
          Sing in
        </a>
        <a *ngIf="!user" (click)='register()' class="footer__menu--link" i18n="@@sing_up">
          Sign up
        </a>
        <a *ngIf="user" (click)='goToUserCabinet()' class="footer__menu--link" i18n>
          Personal cabinet
        </a>
        <!-- <a *ngIf="user" href="https://os.verteraorganic.com/lkc.php" class="footer__menu--link" target="_blank" i18n>Partner's office</a> -->
      </div>
    </div>

    <div class="footer__col">
      <!--
      <div class="footer__social">
        <div class="footer__col--title" i18n>WE IN SOCIAL NETWORKS</div>
        <a *ngIf="lang !== 'ru'" href="https://www.facebook.com/verteraorganic" class="footer__social--link" target="_blank" >
          <svg-icon name="social--fb" class="fb"></svg-icon>
        </a>
        <a href="https://vk.com/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--vk" class="vk"></svg-icon>
        </a>
        <a *ngIf="lang !== 'ru'" href="https://www.instagram.com/vertera_company/" class="footer__social--link" target="_blank">
          <svg-icon name="social--inst" class="inst"></svg-icon>
        </a>
        <a href="https://www.youtube.com/user/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--youtube" class="youtube"></svg-icon>
        </a>
        <a href="https://ok.ru/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--ok" class="ok"></svg-icon>
        </a>
      </div>
      -->
      <div class="footer__contact">
        <a href="tel:+905392223730" class="footer__contact--phone">+90 (539) 222-37-30</a>
        <span class="footer__contact--text">
          <span>E-mail:</span> <a href="mailto:turkey@vertera.org">turkey@vertera.org</a>
        </span>
        <span class="footer__contact--text">
           <span>Teknik yardım:</span> <a href="mailto:support@wr.company">support@wr.company</a>
        </span>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <div class="footer--half">
        <span class="footer--copy">&copy;</span>
        <span>2013 - {{ copyright }} Vertera. Doğal ürünlerle hayatın tadını çıkarın!</span>
      </div>
      <div class="footer--half">
        <a routerLink='/policy' target="_blank" class="footer--link">Gizlilik Politikası</a>
        <a routerLink='/teslimat' target="_blank" class="footer--link">Iade ve teslimat</a>
        <a routerLink="/terms" class="footer--link">Site koşulları</a>
      </div>
    </div>
  </div>
  <div class="footer__scroll" [ngClass]="{ 'scroller': scroller }" (click)="scrollToTop()">
    <img appStatic="/assets/icons/ic/scroll-top.svg" />
  </div>
</footer>
