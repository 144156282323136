import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { environment } from 'src/environments/environment';
import { BASE_URL } from './core/lib/api.factory';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { Angulartics2Module } from 'angulartics2';
import { MetrikaModule } from 'ng-yandex-metrika';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NotificationModule } from './ui/notification/notification.module';
import { NotificationService, NgAnimateScrollService } from '@core/services';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeHu from '@angular/common/locales/hu';

registerLocaleData(localeTr);
registerLocaleData(localeHu);

const yaMetrikaConf = {
  id: environment.vertera_turkey.yaMetrika,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    CoreModule.forRoot(),
    Angulartics2Module.forRoot(),
    MetrikaModule.forRoot(yaMetrikaConf, environment.vertera_turkey.yaMetrika),
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    NotificationModule,
  ],
  providers: [
    {
      provide: BASE_URL,
      useValue: environment.baseUri
    },
    CookieService,
    NotificationService,
    NgAnimateScrollService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
