import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { OrderQueryBuilderService } from './order-query-builder.service';
import { DeliveryTypeIds, CartDelivery, OrderQuery, UserAddressModel } from '@core/models';
import { StorageService } from '@core/services/storage/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '@core/services';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { getPhone } from '@core/lib/functions/phone';
import { DeliveryPoint, VRStorage } from '@core/models/storage/storage.model';
import { mergeMap } from 'rxjs/operators';
import { QueryLogService } from '@core/services/common/query-log.service';
import { LanguageService } from '@core/services/language.service';
import { CityService } from '@core/services/hdbk/city.service';
import { MatDialog } from '@angular/material';
import { validationErrorText } from '@core/lib/functions/errors';
import { TextDialogComponent } from '@core/dialogs/text-dialog/text-dialog.component';

interface RegistrationData {
  fname: string;
  lname: string;
  phone: string;
  email: string;
  passport_id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeliveryViewService implements OnDestroy {
  userAddress: string;
  submit$ = new Subject();
  sub$: Subscription = new Subscription();
  isProcessing: boolean;
  private activeForm: any;
  public addressForm: any = {};
  public customerForm: any = {};
  selectedStorage: VRStorage;
  deliveryPoint: DeliveryPoint;
  activeAddress: UserAddressModel;
  orderToken: string;

  constructor(
    private orderQueryBuilderService: OrderQueryBuilderService,
    private storageService: StorageService,
    private router: Router,
    private userService: UserService,
    private authTokenService: AuthTokenService,
    private queryLogService: QueryLogService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private cityService: CityService,
    private dialog: MatDialog
  ) {  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  setActiveForm(form) {
    this.activeForm = form;
  }


  getAddress(orderQuery = this.orderQueryBuilderService.getOrderQuery()): string {
   if (orderQuery.delivery_type_const === DeliveryTypeIds.self_pickup) {
    const storage = this.storageService.getStorage();
    if (storage) {
      return storage.address;
    }
   } else if (orderQuery.delivery_type_const === DeliveryTypeIds.ozon_pickpoint) {
     return this.deliveryPoint.address;
   }
   else if (orderQuery.delivery_type_const === DeliveryTypeIds.dpd_courier ||
              orderQuery.delivery_type_const === DeliveryTypeIds.courier ) {
      return this.userAddress;
   }
  }

  submit(delivery?: CartDelivery) {
    this.submit$.next();
    if (delivery) {
      const orderQuery = new OrderQuery();
      orderQuery.delivery_type_const = delivery.type.id;
      orderQuery.delivery_type_calculation_const = delivery.type_calculation.id;
      if(delivery.type.id === DeliveryTypeIds.self_pickup) {
        orderQuery.delivery_point_id = this.selectedStorage.id.toString();
      } else if (delivery.type.id === DeliveryTypeIds.ozon_pickpoint) {
        orderQuery.delivery_point_id = this.deliveryPoint.id.toString();
        orderQuery.storage_id = this.deliveryPoint.storage_id;
      }

      this.orderQueryBuilderService.createOrderQuery(orderQuery, this.getAddress(orderQuery));
      if (delivery.type.id === DeliveryTypeIds.courier
      || delivery.type.id === DeliveryTypeIds.dpd_courier
      || delivery.type.id === DeliveryTypeIds.ozon_pickpoint) {
        this.storageService.setStorage(delivery.storage);
        this.selectedStorage = delivery.storage;
      }

      if (!this.authTokenService.getAuthHeader()) {
        this.submitWithRegistration(delivery);
      } else {
        this.submitWithoutRegistration(delivery);
      }
    }
  }

  submitWithoutRegistration(delivery: CartDelivery) {

    if (this.activeAddress ) {
        this.goToCheckout();
      } else {
        if (this.activeForm.valid && this.selectedStorage) {
          if (!this.activeAddress && (delivery.type.id === DeliveryTypeIds.courier
            || delivery.type.id === DeliveryTypeIds.dpd_courier)) {
              const addressQuery = {
                ...this.activeForm.getRawValue(),
                ...{phone: getPhone(this.activeForm.value.phone)}
              };
              this.isProcessing = true;
              const sub = this.userService.setAddress(addressQuery).subscribe((address: UserAddressModel) => {
                const orderQuery = new OrderQuery();
                orderQuery.delivery_user_address_id = address.id;
                orderQuery.fname = addressQuery.fname;
                orderQuery.lname = addressQuery.lname;
                orderQuery.phone = addressQuery.phone;
                orderQuery.email = addressQuery.email;
                this.orderQueryBuilderService.createOrderQuery(orderQuery, address);
                this.goToCheckout();
              }, err => this.handleError(err));
              this.sub$.add(sub);

            } else {
              this.goToCheckout();
            }
        }
      }
  }

  submitWithRegistration(delivery: CartDelivery) {
    // courier delivery
    if (delivery.type.id === DeliveryTypeIds.courier
      || delivery.type.id === DeliveryTypeIds.dpd_courier ) {
      if (this.activeAddress) {
        this.registerAndAddAddress(this.activeAddress);
        return;
      } else {
        if (this.activeForm.valid) {
          this.isProcessing = true;
          this.registerAndAddAddress({
            ...this.activeForm.getRawValue(),
            ...{phone: getPhone(this.activeForm.value.phone)}
          });
        }

      }
    } else {  // self pickup
      if (this.activeForm.valid && this.selectedStorage) {
        this.storageService.setStorage(this.selectedStorage);
        this.isProcessing = true;
        this.regUserInBackground({
          ...this.activeForm.value,
          ...{phone: getPhone(this.activeForm.value.phone)}
        });
      }
    }
  }


  regUserInBackground(regData: RegistrationData) {
    this.userService.regModel = {
      first_name: regData.fname,
      last_name: regData.lname,
      phone: regData.phone,
      email: regData.email,
      is_return_exist_user: true,
      country_code: 'TR',
      language: 'tr'
    };
    this.sub$.add(
      this.userService.register(this.userService.regModel).pipe(mergeMap((data) => {
        this.setOrderToken(data.token);
        return this.queryLogService.sendQueryLog(this.route.snapshot.queryParams);
      })).subscribe(() => {
        this.createOrderQueryOnBackgroundReg(regData);
        this.goToCheckout();
      }, err => this.handleError(err))
    );
  }

  registerAndAddAddress(address: UserAddressModel) {
    this.isProcessing = true;
    this.userService.regModel = {
      first_name: address.fname,
      last_name: address.lname,
      phone: address.phone,
      email: address.email,
      is_return_exist_user: true,
      country_code: 'TR',
      language: 'tr'
    };



    const sub = this.userService.register(this.userService.regModel).pipe(
     mergeMap((data) => {
      this.setOrderToken(data.token);
      return this.queryLogService.sendQueryLog(this.route.snapshot.queryParams);
     }),
     mergeMap(() => {
      return this.userService.setAddress(address);
    })
    ).subscribe((data) => {
      this.createOrderQueryOnBackgroundReg(address, data.id);
      this.goToCheckout();
    }, err => this.handleError(err));

    this.sub$.add(sub);

  }

  createOrderQuery(regData: RegistrationData, address?: any) {
    const query = new OrderQuery();
    if (address.id) {
      query.delivery_user_address_id = address.id;
    }
    query.fname = regData.fname;
    query.lname = regData.lname;
    query.phone = regData.phone;
    query.email = regData.email;
    query.passport_id = regData.passport_id;
    this.orderQueryBuilderService.createOrderQuery(query, address);
    return query;
  }

  createOrderQueryOnBackgroundReg(regData: RegistrationData, addressId?: number) {
    const query = this.createOrderQuery(regData, {id: addressId } );
    query.is_background_registration = 1;
    this.orderQueryBuilderService.createOrderQuery(query, this.getAddress());
  }

  goToCheckout() {
    this.router.navigate(['/cart/checkout']);
    this.isProcessing = false;
  }

  private setOrderToken(token: string) {
    this.orderToken = `Bearer ${token}`;
  }

  private handleError(err) {
    this.isProcessing = false;
    if (err && err.error) {
      this.dialog.open(TextDialogComponent, {
        width: '300px',
        panelClass: 'modal-form-error',
        data: validationErrorText(err.error)
     });
    }
  }
}
