import { LanguageService } from '@core/services/language.service';
import { Component, PLATFORM_ID, Inject, ChangeDetectorRef } from '@angular/core';
import { CityService } from '@core/services/hdbk/city.service';
import { GeoService } from '@core/services/geo.service';
import { GeoLandDetected } from '@vertera-common/abstract/geo-land-detected';



@Component({
  selector: 'app-geo-select',
  templateUrl: './geo-select.component.html',
  styleUrls: ['./geo-select.component.scss']
})
export class GeoSelectComponent extends GeoLandDetected {

  constructor(
    @Inject(PLATFORM_ID) protected platform: any,
    protected languageService: LanguageService,
    protected cityService: CityService,
    protected changeDetector: ChangeDetectorRef,
    protected geoService: GeoService,
  ) {
    super(platform, languageService, cityService, changeDetector, geoService);
  }

  toggleGeoMenu() {
    this.openGeoMenu = ! this.openGeoMenu;
  }

  closeGeoMenu() {
    this.openGeoMenu = false;
  }
}
