<div class="dropdown" [class._opened]="openGeoMenu">
  <app-transparent-button class="menu__geo--button dropdown-toggle" pt="pt0" (click)="toggleGeoMenu()">
    <svg-icon name="geo--marker" [size]="16" class="menu__geo--icon"></svg-icon>
    <span class="menu__geo--text">Şehriniz:</span>
    <span class="menu__geo--city">{{ value?.name }}</span>
  </app-transparent-button>

  <div class="dropdown-menu" (mouseleave)='closeGeoMenu()'>
    <p>Teslimat koşullarını daha doğru bir şekilde hesaplayabilmemiz için bölgeyi belirtin</p>
    <ng-select [items]="citiesForDD"
               (change)="onSelectCity($event)"
               (close)="clear()"
               (search)="search($event)"
               [placeholder]="value?.name"
               [loading]="isLoading"
               [typeahead]="search$"
               [clearable]="true"
               typeToSearchText="Aramak için yazmaya başlayın"
               notFoundText='Şehir bulunamadı'
               class="hidden-arrow"
               loadingText='Yükleniyor...'
               bindLabel="name">
               <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                 {{ item.name }}
               </ng-template>
    </ng-select>

    <!-- <div class="cities">
      <span class="dashed city"*ngFor="let city of defaultCities; let isLast = last"
              (click)="onSelectCity(city, $event)">{{ city?.name }}{{isLast ? '' : ','}}</span>
    </div>

    <app-white-button class="geo-select__identify" (click)="onAutoDetect()">
      <svg-icon name="geo--marker" [size]="16"></svg-icon>
      <span class="check">Konumuma Göre Bul</span>
    </app-white-button> -->
  </div>
</div>

