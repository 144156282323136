
<div class="container">
  <div class="cookies__close">
    <svg-icon name="control--close" size="12" (click)="close()"></svg-icon>
  </div>
  <div class="cookies">
    <div class="cookies--icon">
      <svg-icon name="control--info" size="30"></svg-icon>
    </div>
    <div class="cookies--text">
      <p>
        <span>
          Web sitemizin düzgün çalışmasını sağlamak ve hizmet kalitesini artırmak için çerezleri kullanıyoruz. Daha fazla bilgi için
        </span>
        <a routerLink="/policy" target="_blank" class="cookies--link">GİZLİLİK VE KİŞİSEL VERİ POLİTİKASI.</a>
      </p>
    </div>
    <div class="cookies__button" >
      <app-green-button (click)="accept()">
        <span i18n="@@cookies_accept" >Accept</span>
      </app-green-button>
    </div>
  </div>
</div>

