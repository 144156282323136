<div class="header" [ngClass]="{'fixed': stickyHeader, 'opened': showCatalogMenu }">
  <div class="container">
    <div class="header__top" [class.show]="showAside">
      <div class="menu__left">
        <div class="menu--item">
          <app-geo-select *ngIf="widthscreen > 991"></app-geo-select>
        </div>
        <div class="menu--item"><a routerLink="/teslim" >Teslim</a></div>
        <div class="menu--item"><a routerLink="/odeme" >Ödeme</a></div>
        <!--
        <div class="menu--item"><a href="https://old.dev.vertera.org/geography" target="_blank">География</a></div>
        -->
        <div class="menu--item"><a class="menu--item_link" [href]="globalLink" target="_blank"><svg-icon [size]="16" name="ic--global"></svg-icon><span>Vertera Global</span></a></div>
      </div>
      <div class="menu__right">
        <!--
        <div class="menu--item">
          <a routerLink="/faq" i18n>Help</a>
        </div>
        -->
        <!-- <div class="menu--item">
          <a href="{{ verteraHost }}/help/faq">Yardim</a>
        </div> -->
       <a href="tel:+905392223730" class="menu--item phone">+90 (539) 222-37-30</a>
        <!--
        <app-language-switcher [supportedLanguages]="['ru','en','es','cs','bg']"></app-language-switcher>
        -->
    </div>
      <div class="menu__mobile">
        <div class="menu__mobile--header">
          <app-geo-select *ngIf="widthscreen <= 991"></app-geo-select>
          <!--
          <app-language-switcher [supportedLanguages]="['ru','en']"></app-language-switcher>
          -->
        </div>
        <!-- TODO: вывести общее число монет у пользователя -->
        <div class="menu__mobile--header"  *ngIf="widthscreen < 576">
          <!--
            <span>Монеты</span>
            <span class="menu__mobile--header-points" *appIfAuth>
              0.99 <img appStatic="/assets/img/cart/y-points.svg" />
            </span>
          -->

          <app-white-button
              (click)="toggleAside()"
              routerLink="/catalog">
            <span>Ürün Kataloğu</span>
          </app-white-button>

        </div>

        <div class="menu__mobile--body tiny-scroll">
         

          <ul>
            <li>
              <a routerLink="/teslim" (click)="toggleAside()">Teslim</a>
            </li>
            <!--
            <li>
              <a routerLink="/odeme" (click)="toggleAside()">ödeme</a>
            </li>
            -->
            <!--
            <li>
              <a href="https://os.verteraorganic.com/lkc.php?logout=yes" target="_blank" i18n>Partner's office</a>
            </li>
            <li>
              <a href="https://old.dev.vertera.org/geography" target="_blank">География</a>
            </li>
            -->
          </ul>
        </div>
        <div class="menu__mobile--footer">
          <span>Çok kanallı telefon</span>
          <a href="tel:+905392223730">+90 (539) 222-37-30</a>
        </div>
      </div>
    </div>
  </div>
  <div class="header__bottom">
    <div class="container">
      <app-transparent-button class="header__hamburger" (click)="toggleAside()" [class.show]="showAside">
        <svg-icon [name]="showAside ? 'control--close': 'control--hamburger-dark'" [size]="24"></svg-icon>
      </app-transparent-button>
      <div id='scroll_anchor'></div>
      <a routerLink="/" class="header__logo" [class.login]="user && !isUserLoading" [class.new_year]="newYear">
        <img appStatic="/assets/img/logo.svg" alt="Vertera" class="header__logo--image">
        <img appStatic="/assets/img/logo-sm.png" alt="Vertera" class="header__logo--image sm">
        <ng-container *ngIf="newYear">
          <img appStatic="/assets/img/new-year/garland_xs.svg" alt=""  class="header__logo--image--ny sm">
          <img appStatic="/assets/img/new-year/garland.svg" alt=""  class="header__logo--image--ny">
        </ng-container>
      </a>
      <app-green-button class="header__button--catalog"
                        *ngIf="widthscreen >= 576"
                        [class.login]="user && !isUserLoading"
                        [class.opened]="showCatalogMenu"
                        routerLink="/catalog"
                        [red]="showCatalogMenu ? 'red': ''">
        <span>Ürün Kataloğu</span>
      </app-green-button>
      <app-search-form [class.login]="user" class="{{ language }}"></app-search-form>
      <div class="content-loader" *ngIf='isUserLoading'></div>
      <div *ngIf="!user && !isUserLoading" class="header__bottom--menu no-login {{ language }}">
        <div class="menu--item">
          <a routerLink="/cart">
            <svg-icon name="control--cart" size="24"></svg-icon>
            <span>Sepetim</span>
          </a>
          <span class="menu--item-count red">
            <span>{{cartViewService.totalAmount}}</span>
          </span>
        </div>
        <div class="menu--item">
          <a (click)='login()'>
            <svg-icon name="control--lock" size="24"></svg-icon>
            <span i18n="@@sign_in">Sing in</span>
          </a>
        </div>
        <a class="reg__link" (click)='register()' i18n="@@sing_up">
          Sign up
        </a>
      </div>
      <div *ngIf="user && !isUserLoading" class="header__bottom--menu user-menu">

        <div class="menu--item favorites" >
          <a routerLink='/account/favorites'>
            <svg-icon name="control--favorites" size="24"></svg-icon>
            <span i18n='@@header.favorites'>Favorite</span>
            <span class="menu--item-count red">
              <span>{{favoritesCount}}</span>
            </span>
          </a>
        </div>

        <div class="menu--item stock">
          <a (click)="toggleStockPanel()">
            <svg-icon name="ic--giftt" size="24"></svg-icon>
            <span i18n>Stocks</span>
            <span class="menu--item-count red">
                <span>{{stocks.length}}</span>
              </span>
          </a>
        </div>

        <div class="menu--item dropdown" [class._opened]="_opened">
          <a class="dropdown-toggle" id="userMenuButton" (click)="toggleUserMenu()">
            <svg-icon name="ic--profile" size="24"></svg-icon>
            <span>Profil</span>
          </a>
          <ul class="dropdown-menu custom-dropdown" aria-labelledby="userMenuButton">
            <li class="dropdown-item">
              <a i18n='@@lk.profile' class="link" routerLink='/account/profile'>
                <span>Profile</span>
              </a>
            </li>
            <li class="dropdown-item">
              <a i18n='@@lk.orders_history' class="link" routerLink='/account/orders-history'>
                <span>Order history</span>
              </a>
            </li>
            <li class="dropdown-item">
              <a i18n='@@lk.ref_link' class="link" routerLink="/account/referral">
                <span>Referral link</span>
              </a>
            </li>
            <li class="dropdown-item">
            <a class="link" (click)='logout()'>
              Çıkış Yap
            </a>
           </li>
          </ul>
        </div>
        <div class="menu--item cart" routerLink="/cart">
          <a>
            <svg-icon name="control--cart" size="24"></svg-icon>
            <span>Sepetim</span>
          </a>
          <span class="menu--item-count red">
            <span>{{cartViewService.totalAmount}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
    <app-category [fixed]="stickyHeader" *ngIf="widthscreen >= 576" [showCatalog]="showCatalogMenu" (onClose)="closeCatalogMenu()"></app-category>
</div>
<div class="container">
  <app-breadcrumbs></app-breadcrumbs>
</div>

<app-stock-panel
  [stocks]='stocks'
  [lang]='language'
  (clickOutside)="onClickedOutsideStock($event)"
  [class.sticky]="sticky"
  [class.opened]="open_panel"
  (onChanged)="onChanged()">
</app-stock-panel>
