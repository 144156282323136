import { LanguageService } from '@core/services/language.service';
import { debounceTime, distinctUntilChanged, switchMap, filter, map } from 'rxjs/operators';
import { OnInit, OnDestroy, PLATFORM_ID, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { CityQueryModel } from '@core/models/geo/city.model';
import { Subscription, combineLatest, Subject } from 'rxjs';
import { CityService } from '@core/services/hdbk/city.service';
import { GeoService } from '@core/services/geo.service';

export abstract class GeoLandDetected implements OnInit, OnDestroy {
  openGeoMenu: boolean;
  /**
   * Выбран ли пользователем язык (не автоопределение)
   */
  isSelectedCity = false;

  /**
   * Показывать ли кнопку возле селекта для выбора города
   */
  isShowSelectButton = false;
  defaultCities: { name: string }[];
  cities = [];
  citiesForDD = [];

  /**
   * Пред выбор, потом будем получать с апи определенный город пользователем
   * Или с localstorage
   */
  value = this.cities[0];

  /**
   * Предварительный выбор города в селекте
   * Пока не нажмет кнопку выбора город не запишется в value
   */
  selectValue: any = this.value;
  private query = new CityQueryModel();
  readonly sub = new Subscription();
  public search$ = new EventEmitter<string>();
  private allowSearch$ = new Subject<boolean>();
  isLoading: boolean;

  constructor(
    @Inject(PLATFORM_ID) protected platform: any,
    protected languageService: LanguageService,
    protected cityService: CityService,
    protected changeDetector: ChangeDetectorRef,
    protected geoService: GeoService,
  ) { }

  ngOnInit() {
    const myCity = this.cityService.getCity();
    if (myCity && myCity.id === this.cityService.CITY.defaultCity.id) {
      this.selectCity(this.cityService.CITY.defaultCity);
    } else {
      this.onSelectCity(myCity);
    }
    this.defaultCities = this.geoService.defaultCities[this.languageService.getLanguage()];
    this.subscribeToSearch();
    this.subscribeToCityChange();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * Применение выбранного горада
   */
  onChangeSelect(city) {
    if (city) {
      this.selectValue = city;
    } else {
      setTimeout(() => {
        this.selectValue = city;
      }, 10);
    }
  }

  clear() {
    this.citiesForDD = [];
  }

  search(event) {
    if (!event.term) {
      this.citiesForDD = [];
      this.allowSearch$.next(false);
    } else {
      this.allowSearch$.next(true);
    }
  }

  subscribeToSearch() {
    const sub = combineLatest(this.search$, this.allowSearch$).pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter(([term, allow]) => allow && !!term),
      map(([term, allow]) => term),
      switchMap((term: string) => {
        this.isLoading = true;
        this.query.search = term;
        this.query.country_id = this.cityService.getCountryId();
        return this.cityService.getPage(this.query);
      }),
      filter(res => res && !!res.data),
      map(res => res.data)
    ).subscribe(res => {
      this.citiesForDD = res;
      this.isLoading = false;
      this.changeDetector.detectChanges();
    });
    this.sub.add(sub);
  }

  /**
   * Выбор города в селекте
   */
  onSelectCity(city, $event?) {
    if (city) {
      this.selectCity(city);
      this.cityService.setCity(city);
    }
    if ($event) {
      $event.stopPropagation();
    }
  }

  selectCity(city) {
    this.value = city;
    this.selectValue = city;
    this.isSelectedCity = true;
    this.isShowSelectButton = false;
    this.openGeoMenu = false;
  }

  /**
   * Событие открытие попапа селекта
   * Показать кнопку выбора, очистить значение селекта чтобы кнопка стала disable
   */
  onOpenSelect() {
    this.isShowSelectButton = true;
  }

  onCloseSelect() {
    this.isShowSelectButton = false;
  }



  subscribeToCityChange() {
    const sub = this.cityService.cityChange$.subscribe((city) => {
      this.selectCity(city);
    });
    this.sub.add(sub);
  }
}
